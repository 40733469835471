import React from 'react';
import { forwardRef } from 'react';
import blue from '@material-ui/core/colors/blue';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RenewPwd from '@material-ui/icons/Autorenew'
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import Send from '@material-ui/icons/Send';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Forward from '@material-ui/icons/Forward';
import Folder from '@material-ui/icons/Folder';
import Payment from '@material-ui/icons/Payment'
import Gavel from '@material-ui/icons/Gavel';
import Receipt from '@material-ui/icons/Receipt';
import Acknowledge from '@material-ui/icons/Done';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import DoneAction from '@material-ui/icons/CheckCircleOutline'
import Preview from '@material-ui/icons/PictureInPicture'
import DoubleArrow from '@material-ui/icons/DoubleArrow'
import Code from '@material-ui/icons/Code'
import Timeline from '@material-ui/icons/Timeline'
import MoreVert from '@material-ui/icons/MoreVert';
import Visibility from '@material-ui/icons/Visibility';

import styled from 'styled-components';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Download } from './download.svg';
import * as Affidavit from './affidavit-icon.png';
import * as Invoice from './invoice-icon.png';
import {iconColor} from '../tempfile'

const goldColor = '#ffd700';

const SvgIcon = styled.svg`
    width: 1.6rem;
    height: 1.6rem;
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

const PngIcon = styled.img`
    width: 1.6rem;
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const UploadIcon = () => (
    <SvgIcon viewBox="0 0 50 50">
        <Upload />
    </SvgIcon>
);

export const DownloadIcon = () => (
    <SvgIcon viewBox="0 0 50 50">
        <Download />
    </SvgIcon>
);

export const RenewPwdIcon = () => (
    <SvgIcon viewBox="0 0 50 50">
        <RenewPwd />
    </SvgIcon>
);

export const BellIcon = styled(NotificationsActive)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const SendIcon = styled(Send)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const AssignmentTurnedInIcon = styled(AssignmentTurnedIn)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const ForwardIcon = styled(Forward)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const FolderIcon = styled(Folder)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const PaymentIcon = styled(Payment)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const AffidavitIcon = styled(Gavel)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const AffidavitIcon2 = styled(Gavel)`
    path {
        fill: ${goldColor};
        stroke: ${goldColor};
    }
`;

export const InvoiceIcon = styled(Receipt)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const AcknowledgeIcon = styled(Acknowledge)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const AddBoxIcon = styled(AddBox)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const ArrowUpwardIcon = styled(ArrowUpward)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const CheckIcon = styled(Check)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const ChevronLeftIcon = styled(ChevronLeft)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const ChevronRightIcon = styled(ChevronRight)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const ClearIcon = styled(Clear)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const DeleteOutlineIcon = styled(DeleteOutline)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const EditIcon = styled(Edit)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const FilterListIcon = FilterList;

export const FirstPageIcon = FirstPage;

export const LastPageIcon = LastPage;

export const RemoveIcon = styled(Remove)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const SaveAltIcon = styled(SaveAlt)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const SearchIcon = styled(Search)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;
export const ViewColumnIcon = styled(ViewColumn)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const ShoppingIcon = styled(ShoppingCart)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const DoubleArrowIcon = styled(DoubleArrow)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const DoneActionIcon = styled(DoneAction)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const PreviewIcon = styled(Preview)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const CodeIcon = styled(Code)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const TimelineIcon = styled(Timeline)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const MoreVertIcon = styled(MoreVert)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const VisibilityIcon = styled(Visibility)`
    path {
        fill: ${iconColor};
        stroke: ${iconColor};
    }
`;

export const TableIcons = {
    Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutlineIcon {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <RemoveIcon {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumnIcon {...props} ref={ref} />)
};
