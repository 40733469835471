import {
	START_UPDATE,
	UPDATE_COMPLETED,
	EMAIL_SENT,
	TOGGLE_BATCH_ACTIONS,
	TOGGLE_PDF,
	ENABLE_BATCH_ACTIONS,
	UPDATE_SELECTED_ROWS,
	ENABLE_AFFIDAVIT_ACTIONS,
	ENABLE_DOC_UPLOAD_ACTIONS,
	ENABLE_DOC_DISPLAY_MODAL,
	DISABLE_ACTIONS,
	ACKNOWLEDGE_JOBS,
	START_REPORT,
	ENABLE_MESSAGE_MODAL,
	ENABLE_JOB_SELECTION
} from './types'

import axios from 'axios';
import { Vendor_ApiKey, ServerPath } from '../tempfile';

export const startUpdate = (currentScreen) => {
  return (dispatch) => {
  	dispatch({ type: START_UPDATE, payload: currentScreen});
		console.log("dashboard update started")
  }
};

export const completeUpdate = () => {
  return (dispatch) => {
  	dispatch({ type: UPDATE_COMPLETED});
		console.log("dashboard update completed")
  }
};

export const sendEmail = () => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken
		};
    return axios.post(ServerPath + `/api/sendEmail`, data).then(response => {
      dispatch({ type: EMAIL_SENT, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
};

export const toggleBatchActions = () => {
  return (dispatch) => {
  	dispatch({ type: TOGGLE_BATCH_ACTIONS});
  }
};

export const togglePDFOn = () => {
	return (dispatch) => {
  	dispatch({ type: TOGGLE_PDF});
  }
}


export const enterModal = (key, val) => {
	return (dispatch) => {
		console.log("enter modal called")
		switch(key){
			case "batch":
				dispatch({ type: ENABLE_BATCH_ACTIONS});
				break;
			case "affidavit":
				console.log(val)
				dispatch({ type: ENABLE_AFFIDAVIT_ACTIONS, payload: val});
				break;
			case "docs":
				dispatch({ type: ENABLE_DOC_UPLOAD_ACTIONS, payload: val});
				break;
			case "display":
				dispatch({ type: ENABLE_DOC_DISPLAY_MODAL, payload: val});
				break;
			case "messages":
				dispatch({ type: ENABLE_MESSAGE_MODAL, payload: val});
				break;
			case "report_form":
				dispatch({ type: ENABLE_JOB_SELECTION, payload: val});
				break;
			case "dismiss":
				dispatch({ type: DISABLE_ACTIONS, payload: val})
				break;
		}
  }
}

export const updateRowSelection = (val) => {
	return (dispatch) => {
  	dispatch({ type: UPDATE_SELECTED_ROWS, payload: val});
  }
}

export const selectFiles = () => {
  return (dispatch) => {
		return new Promise((resolve, reject) => {
      var input = document.createElement('input');
      input.type = 'file';
      input.multiple = 'multiple';

      input.onchange = e => {
          const files = e.target.files;
          resolve(Array.from(files));
      };

      input.click();
  	});
	}
}

export const acknowledgeOrders = (job_ids) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: job_ids
		};
		console.log(data);
    return axios.post(ServerPath + `/api/acknowledgeJobs`, data).then(response => {
			console.log(response);
			dispatch({ type: ACKNOWLEDGE_JOBS, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
};

export const startReport = () => {
	return (dispatch) => {
  	dispatch({ type: START_REPORT});
  }
}
