// AUTH
export const LOGIN_SUCCEEDED = "login_success";
export const LOGIN_FAILED = "login_fail";
export const LOGOUT_SUCCEEDED = "logout_success";
export const LOGOUT_FAILED = "logout_fail";
export const VENDOR_LOADED = "vendor_loaded";

// UPDATE
export const START_UPDATE = "start_update";
export const UPDATE_COMPLETED = "update_completed";
export const EMAIL_SENT = "email_sent";
export const TOGGLE_BATCH_ACTIONS = "toggle_batch_actions";
export const TOGGLE_PDF = "toggle_pdf";
export const ENABLE_BATCH_ACTIONS = "enable_batch_actions";
export const UPDATE_SELECTED_ROWS = "update_selected_rows";
export const ENABLE_AFFIDAVIT_ACTIONS = "enable_affidavit_actions";
export const DISABLE_ACTIONS = "disable_actions";
export const ENABLE_DOC_UPLOAD_ACTIONS = "enable_doc_upload_actions";
export const ENABLE_DOC_DISPLAY_MODAL = "enable_doc_display_modal";
export const ACKNOWLEDGE_JOBS = "acknowledge_jobs";
export const START_REPORT = "start_report";
export const ENABLE_MESSAGE_MODAL = "enable_message_modal";
export const ENABLE_JOB_SELECTION = "enable_job_selection";

// GET
export const JOBS_RETURNED = "jobs_returned";
export const CASES_RETURNED = "cases_returned";
export const REPORTS_RETURNED = "reports_returned";
export const VENDORS_RETURNED = "vendors_returned";
export const CODES_RETURNED = "get_names";
export const MESSAGES_RETURNED = "messages_returned";
export const DOCUMENTS_RETURNED = "documents_returned";
export const SOFT_REFRESH = "soft_refresh";

// ADD
export const REPORTS_ADDED = "reports_added";
export const MESSAGES_ADDED = "messages_added";
export const DOCS_ADDED = "docs_added";
export const AFFIDAVITS_ADDED = "affidavits_added";
export const REPORTS_EDITED = "reports_edited";
