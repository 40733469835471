import {
	JOBS_RETURNED,
  CASES_RETURNED,
  REPORTS_RETURNED,
  COURTS_RETURNED,
	CODES_RETURNED,
	MESSAGES_RETURNED,
	DOCUMENTS_RETURNED,
	SOFT_REFRESH
} from '../actions/types'

import 'moment-timezone';
const moment = require('moment');

const INITIAL_STATE = {
	jobs: null,
  reports: null,
	codes: null,
	messages: null,
	documents: null
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case JOBS_RETURNED:
			return { ...state, jobs: action.payload };
		case REPORTS_RETURNED:
			return { ...state, reports: action.payload };
		case CODES_RETURNED:
			console.log("got codes")
			console.log(action.payload)
			return { ...state, codes: action.payload};
		case MESSAGES_RETURNED:
			return { ...state, messages: action.payload};
		case DOCUMENTS_RETURNED:
			return { ...state, documents: action.payload};
		case SOFT_REFRESH:

			let updated = [...state.jobs];
			for(let i = 0; i < updated.length; i++){
				if(updated[i].job_id == action.payload.job_id){
					updated[i].reports = action.payload.data;
				}
			}
			return { ...state, jobs: updated};
		default:
			return { ...state };
  }
}
