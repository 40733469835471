import React, { Component } from 'react';
import { connect } from 'react-redux'
import MaterialGrid from '../../components/material_grid'
import {enterModal, getJobs, acknowledgeOrders, getDocuments, editReport, softRefresh, generateAffidavits} from '../../actions'
import { withSnackbar, closeSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Select from 'react-select';

import {
    TableIcons,
    UploadIcon,
    DownloadIcon,
    InvoiceIcon,
    AffidavitIcon,
    AffidavitIcon2,
    BellIcon,
    AssignmentTurnedInIcon,
    FolderIcon,
    AcknowledgeIcon,
    MessageIcon,
    ForwardIcon
} from '../../components/table_icons';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { report_types, theme_color } from '../../tempfile';
import JobModals from '../../components/job_modals'

import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/blue';

export const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  }
});

const maxWidth1 = 100;

export function TooltipTypography({data}){
  return (
    <Tooltip title={data ? data : ''}><Typography noWrap>{data ? data : ''}</Typography></Tooltip>
  );
}

class JobDashboard extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    console.log("hello")

    this.state = {
      title: "Work Orders",
      columns: [
        {"title": "Address", "field": "address",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.address : ''}/>},
        {"title": "Serve By", "field": "due_date",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.due_date : ''}/>},
        {"title": "Document Type", "field": "doc_type",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.doc_type : ''}/>},
        {"title": "Job ID", "field": "job_id", defaultSort: 'desc',
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.job_id : ''}/>},
        {"title": "Recipient", "field": "rec_name",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.rec_name : ''}/>},
        {"title": "Priority", "field": "priority",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.priority : ''}/>},
        {"title": "Status", "field": "job_status",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.job_status : ''}/>},
        {"title": "Affidavit Status", "field": "affidavit_status",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.affidavit_status : ''}/>},
        {"title": "Attempts", "field": "attempts",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.attempts : ''}/>},
        {"title": "Instructions", "field": "instructions",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.instructions : ''}/>},
        {"title": "Assigned", "field": "assigned_date",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.assigned_date : ''}/>},
        {"title": "Acknowledged On", "field": "acknowledged",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.acknowledged : ''}/>},
        {"title": "State", "field": "state", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.state : ''}/>},
      ],
      columns2: [
        {"title": "ID", "field": "report_id", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.report_id : ''}/>},
        {"title": "Type", "field": "type", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.type : ''}/>,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        "editComponent": rowData => <Select
                                  value={{value: rowData.rowData.type, label: rowData.rowData.type}}
                                  onChange={e => rowData.onChange(e.value)}
                                  options={report_types.map((x)=> {return {value: x, label: x}})}
                               />
        },
        {"title": "Caption", "field": "caption",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.caption : ''}/>},
        {"title": "GPS Location", "field": "gps_location", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.gps_location : ''}/>},
        {"title": "Doc. Recipient", "field": "actual_receiver",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.actual_receiver : ''}/>},
        {"title": "Age", "field": "age",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.age : ''}/>},
        {"title": "Gender", "field": "gender",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.gender : ''}/>},
        {"title": "Ethnicity", "field": "ethnicity",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.ethnicity : ''}/>},
        {"title": "Weight", "field": "weight",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.weight : ''}/>},
        {"title": "Height", "field": "height",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.height : ''}/>},
        {"title": "Hair", "field": "hair",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.hair : ''}/>},
        {"title": "Eyes", "field": "eyes",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.eyes : ''}/>},
        {"title": "Relationship", "field": "relationship",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.relationship : ''}/>},
        {"title": "Military", "field": "military", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.military : ''}/>},
        {"title": "Other Details", "field": "other_details",
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.other_details : ''}/>},
        {"title": "Server Name", "field": "server_name", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.server_name : ''}/>},
        {"title": "Server License", "field": "server_license", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.server_license : ''}/>},
        {"title": "Completion Date", "field": "completion_date", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.completion_date : ''}/>},
        {"title": "Log Date", "field": "log_date", editable: false,
        "headerStyle": {maxWidth: maxWidth1}, "cellStyle": {maxWidth: maxWidth1},
        render: rowData => <TooltipTypography data={rowData ? rowData.log_date : ''}/>},
      ],
      filters: {},
      actions: [
          {
              icon: () => <UploadIcon />,
              tooltip: 'Upload Document',
              onClick: (e, c) => {
                  this.props.enterModal("docs", [c]);
              },
          },
          row => ({
              icon: () => <AcknowledgeIcon />,
              tooltip: 'Acknowledge order receipt',
              onClick: (e, c) => {
                this.props.acknowledgeOrders([c.job_id]).then(res => {
                  if(res){
                    this.props.getJobs();
                    this.props.enqueueSnackbar('Order acknowledged', {variant: 'success'})
                  }else{
                    this.props.enqueueSnackbar('Failed to acknowledge order', {variant: 'error'})
                  }
                })
              },
              hidden: row.acknowledged !== ""
          }),
          row => ({
              icon: () => <DownloadIcon />,
              tooltip: 'Download job documents',
              onClick: (e, c) => {
                this.props.getDocuments(["docs_to_serve/job_" + c.job_id + "/*"]).then(res =>{
                  if(res){
                    this.props.enqueueSnackbar('Successful download', {variant: 'success'})
                  }else{
                    this.props.enqueueSnackbar('Failed download', {variant: 'error'})
                  }
                })
              },
              hidden: row.acknowledged === ""
          }),
          row => ({
              icon: () => <ForwardIcon />,
              tooltip: 'Add report to this job',
              onClick: (e, c) => {
                this.props.enterModal("report_form", [c])
              },
              hidden: row.job_status === "Served" || row.acknowledged === ""
          }),
          row => ({
              icon: () => row.affidavit_status == "Not Received" ? <AffidavitIcon2/> : <AffidavitIcon />,
              tooltip: row.affidavit_status == "Not Received" ? 'Generate Unsigned Affidavit' : 'View Current Affidavit',
              onClick: (e, c) => {
                row.affidavit_status == "Not Received" ?
                  this.props.generateAffidavits({job_ids: [c.job_id]})
                  : this.props.enterModal("affidavit", "affidavits/" + c.job_id + "/Affidavit")
              },
              hidden: row.reports.length == 0
          }),
          /*row => ({
              icon: () => <MessageIcon />,
              tooltip: 'View messsages for this job',
              onClick: (e, c) => {
                this.props.enterModal("messages", [c]);
              },
              hidden: row.messages !== undefined && row.messages.length > 0
          }),*/
      ]
    };
  }

  componentDidMount(){
    console.log("this gets called")
    console.log(this.props.jobs)

    this.props.getJobs();
    console.log("end of method")
  }

  getDifferenceArray(old_, new_){
    const diff = {
      //id: old_[this.props.id_column],
      //changes: { }
    }

    Object.keys(old_).map(function (key) {
        if(old_[key] !== new_[key] && key !== "tableData" && key !== "address"){
          diff[key] = new_[key]
        }
    })
    return diff;
  }

  render(){

    const jobs = this.props.jobs ? this.props.jobs : [];

    return (
      <div>
        <MaterialGrid
          title={this.state.title}
          columns={this.state.columns}
          data={jobs}
          id_column="job_id"
          data_type="jobs"
          tableRef={this.myRef}
          actions={this.state.actions}
          detailPanel={[
              {
                  tooltip: 'Show Attempts',
                  render: rowData => {
                    return !rowData.reports ? null :
                    <ThemeProvider theme={theme}>
                    <MaterialTable
                        title={"Reports"}
                        columns={this.state.columns2}
                        data={rowData.reports}
                        options={{
                            editable: true,
                            search: false,
                            toolbar: false,
                            padding: 'dense',
                            emptyRowsWhenPaging: false,
                            paging: false,
                            headerStyle: {
                              padding: '0.5rem',
                              backgroundColor: theme_color,
                              color: 'white',
                            },
                        }}
                        icons={TableIcons}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                              new Promise(resolve => {
                                setTimeout(() => {
                                  resolve();
                                  const changedData = this.getDifferenceArray(oldData, newData);
                                  const id = oldData.report_id
                                  console.log(changedData);
                                  // array needed because editing endpoint requires batch editing elsewhere
                                  this.props.editReport(changedData, id).then((res) => {
                                    if(res){
                                      const data = [...rowData.reports];
                                      data[data.indexOf(oldData)] = newData;
                                      this.props.softRefresh({job_id: rowData.job_id, data: data});
                                      this.props.enqueueSnackbar('Row edited successfully', {variant: 'success'})
                                    }else{
                                      this.props.enqueueSnackbar('Could not edit row', {variant: 'error'})
                                    }
                                  });
                                }, 600);
                              })
                          }}
                    />
                    </ThemeProvider>
                  }
              }
          ]}
        />
        <JobModals/>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    jobs: state.get.jobs,
    filters: state.update.filters
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {enterModal, getJobs, acknowledgeOrders, getDocuments, editReport, softRefresh, generateAffidavits}
)(JobDashboard))
