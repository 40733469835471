import React, { Component } from 'react';
import { connect } from 'react-redux'
import {login, getVendor, getCodes, renewToken, logout, startUpdate, completeUpdate, getJobs} from '../../actions'
import { withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
    Button,
    LinearProgress,
    Typography,
    Box,
    Grid
} from '@material-ui/core';

import {app_user, theme_color} from '../../tempfile'
import * as legalex_logo from '../../images/legalex_logo.png';
import * as nh_logo from '../../images/nh_logo.png';

import { ThemeProvider } from '@material-ui/styles';

import JobDashboard from '../dashboards/job_dashboard'
import SubmitReport from '../dashboards/submit_report'

import BatchActionView from '../../components/batch_actions'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styled from 'styled-components';


const LogoWrapper = styled.img`
    width: ${props => (props.size === 'sm' ? '10rem' : '15rem')};
    padding: 0.5rem 0;
`;

export const theme = createMuiTheme({
  palette: {
    primary: {'main' : theme_color},
    secondary: {'main' : '#d3d3d3'},
  }
});

class MainDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: null,
    };

    this.getInnerRef = this.getInnerRef.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }

  innerRef;
  getInnerRef(ref) {
    this.innerRef = ref;
  }

  getLocation = () => {
    console.log("hey")
    this.innerRef && this.innerRef.getLocation();
    console.log("ho")
  }

  componentDidMount(){
    const username = JSON.parse(window.localStorage.getItem('username')) || null;
    const password = JSON.parse(window.localStorage.getItem('password')) || null;
    this.props.login({username, password}).then((res) => {
      if(!res){
        this.props.history.push('/login');
      }else{
        const smallWait = setInterval(() => {
          if(this.props.apiToken !== null && this.props.login_success){
    				clearInterval(smallWait);
            console.log("current screen")
            console.log(this.props.currentScreen)
            this.props.startUpdate(0);
            console.log("update started")
            this.props.getCodes();
            this.props.getVendor();
            console.log("current screen")
            console.log(this.props.currentScreen)
          }
        }, 100);
      }
    });
  }

  handleLogout = () => {
    console.log("logging out");
    this.props.logout(this.props.apiToken).then((success) => {
      console.log("logout was successful?")
      console.log(success);
      this.props.history.goBack();
    })
  }

  handleChange = (event, newValue) => {
    console.log("handle change")
    console.log(newValue)
    this.props.startUpdate(newValue);
    newValue == 0 ? this.props.getJobs() : this.props.completeUpdate();

  }


  render(){
    console.log("here")
    console.log(this.props.vendor && this.props.codes)

    return (
      <div style={{justifyContent: 'center', alignItems: 'center'}}>
        {this.props.vendor && this.props.codes ?
          <div>
          <ThemeProvider theme={theme}>
            <AppBar position="static" style={{justifyContent: 'center', backgroundColor: "#fff"}}>
            <Grid justify="space-between" alignItems="center" container>
                <Box
                  display="flex"
                  flexDirection="row"
                  p={3}
                  alignItems="center"
                >
                  <LogoWrapper size='sm' src={app_user == "nh" ? nh_logo : legalex_logo} style={{marginRight: 20}}/>
                  <Typography variant="h6" style={{color: theme_color}}>
                    Welcome {this.props.vendor.company}
                  </Typography>
                </Box>
                <Box
                  pr={3}
                  textAlign="center"
                >
                  <Tabs value={this.props.currentScreen} onChange={this.handleChange} indicatorColor="primary">
                    <Tab label="Work Orders" value={0} style={{color: theme_color}}/>
                    <Tab label="Add Attempt" value={1} style={{color: theme_color}}/>
                  </Tabs>
                </Box>
                <Box
                  pr={3}
                  textAlign="right"
                >
                  <Button onClick={this.handleLogout} variant="outlined" style={{color: theme_color, borderColor: 'transparent', justifyContent: 'flex-end'}} >
                  Logout
                  </Button>
                </Box>
              </Grid>
            </AppBar>
            {this.props.currentScreen === 0 ? <BatchActionView style={{margin: 100}}/> : null}
            {this.props.loadingScreen ?  <LinearProgress style={{margin: 80, barColorPrimary: theme_color, backgroundColor:"#fff"}}/> : null }
            {this.props.currentScreen === 0 ? <JobDashboard style={{margin: 100}}/> : <SubmitReport ref={this.getInnerRef} locationButtonAction={this.getLocation}/>}
          </ThemeProvider>
          </div>
        :null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    login_success: state.auth.login_success,
    vendor: state.auth.vendor,
    currentScreen: state.update.currentScreen,
    loadingScreen: state.update.isUpdating,
    codes: state.get.codes
  }
}

export default withRouter(connect(
  mapStateToProps,
  {login, getVendor, getCodes, renewToken, logout, startUpdate, completeUpdate, getJobs}
)(MainDashboard))
