import {
  REPORTS_ADDED,
	MESSAGES_ADDED,
	DOCS_ADDED,
  AFFIDAVITS_ADDED,
  REPORTS_EDITED
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	addingSuccess: false
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case REPORTS_ADDED:
      return { ...state, addingSuccess: action.payload };
		case MESSAGES_ADDED:
			return { ...state, addingSuccess: action.payload };
		case DOCS_ADDED:
			return { ...state, addingSuccess: action.payload };
    case AFFIDAVITS_ADDED:
      return { ...state, addingSuccess: action.payload };
    case REPORTS_EDITED:
      return { ...state, addingSuccess: action.payload };
    default:
      return { ...state };
  }
}
