import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {updateRowSelection} from '../actions'
import {theme_color} from '../tempfile'

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from './table_icons';

import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const theme = createMuiTheme({
  palette: {
    primary: {
        main: theme_color
    },
    secondary: {
        main: theme_color
    },
  }
});


class MaterialGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  getDifferenceArray(old_, new_){
    const diff = { }

    Object.keys(old_).map(function (key) {
        if(old_[key] !== new_[key] && key !== "tableData" && key !== "address"){
          diff[key] = new_[key]
        }
    })
    return diff;
  }


  render(){

    return (
      <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', width: this.state.width}}>
        {this.props.columns !== null && this.props.title !== null && this.props.data !== null ?
          <ThemeProvider theme={theme}>
          <MaterialTable
            icons={tableIcons}
            columns={this.props.columns}
            title={this.props.title}
            data={this.props.data}
            tableRef={this.props.tableRef}
            options={{
              selection: this.props.batch_actions_on,
              editable: false,
              exportButton: true,
              filtering: true,
              pageSize: 20,
              pageSizeOptions: [20, 50, 100, 200],
              headerStyle: {
                padding: '0.5rem',
                backgroundColor: theme_color,
                color: 'white',
                position: 'sticky',
                top: 0
              },
              maxBodyHeight: this.props.maxHeight ? this.props.maxHeight : '650px',
              search: false,
            }}
            detailPanel={this.props.detailPanel}
            onSelectionChange={(rows) => this.props.updateRowSelection(rows)}
            icons={TableIcons}
            actions={this.props.batch_actions_on ? null : this.props.actions}
          />
          </ThemeProvider>
        :null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    batch_actions_on: state.update.batch_actions_on,
    rows_selected: state.update.rows_selected
  }
}

export default connect(
  mapStateToProps,
  {updateRowSelection}
)(MaterialGrid)
