import React, { useContext } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './screens/login/Login';
import {renewToken} from './actions'
import MainDashboard from './screens/main_dashboard/MainDashboard';

function getRoutes({apiToken, loginSuccess, dispatch}) {

  console.log("get routes called")

  return (
      <Router>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/login" component={Login} />
          <Route path="/home" render={() => {
                  return <MainDashboard/>;
              }}
          />
      </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    loginSuccess: state.auth.login_success
  }
}

export default connect(mapStateToProps)(getRoutes)
