import {
  REPORTS_ADDED,
	MESSAGES_ADDED,
	DOCS_ADDED,
  AFFIDAVITS_ADDED,
  REPORTS_EDITED,
} from './types'

import axios from 'axios';
import { MIME_TYPES } from 'util/mime';
import printJS from 'print-js'

import { ServerPath,report_types } from '../tempfile';

export const addReports = (item) => {
	return (dispatch, getState) => {
    console.log(item)
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: item.job_ids,
			age: item.age,
			weight: item.weight.replace(/'/g, "''"),
			height: item.height.replace(/'/g, "''"),
			hair: item.hair,
			eyes: item.eyes,
			ethnicity: item.ethnicity,
			gender: item.gender,
			relationship: item.relationship,
			other_details: item.comments,
			completion_date: item.completion,
			actual_receiver: item.name,
			gps_location: item.gps_location,
			address_id: item.address_id,
      type: item.type == "Successful" ? 1 : 0,
      caption: item.caption,
      server_name: item.server_name,
      server_license: item.server_license,
      military: item.military
		};
		console.log('addReport[data]:', data);
    return axios.post(ServerPath + `/api/addReport`, data).then(response => {
			console.log('addReport[resp]:', response)
			dispatch({ type: REPORTS_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const editReport = (changes, id) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;

		if(changes.type != undefined){
			changes["type"] = report_types.indexOf(changes.type).toString();
		}

		const data = {
			token: apiToken,
			ids: [id],
			type: "report",
			changes: changes,
		};
    return axios.post(ServerPath + `/api/editEntity`, data).then(response => {
			console.log(response)
      dispatch({ type: REPORTS_EDITED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}


export const addDocuments = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		let data = {
			token: apiToken,
			type: item.type,
			ref_id: item.ref_id
		};

		return Promise.all(item.files.map(f => toBase64(f.rawFile))).then(b64Encoded => {
        const docs = {
            doc_list: [],
            doc_bytes: [],
            doc_types: [],
            doc_folders: []
        };
        item.files.forEach((f, i) => {
            const extension = MIME_TYPES.find(t => t.type === f.type).extension;
            docs.doc_list.push(f.name + extension);
            docs.doc_bytes.push(b64Encoded[i]);
            docs.doc_types.push(f.type);
            docs.doc_folders.push(f.destinationFolder);
        });

				data = {...data, ...docs};

				console.log(data)
        return axios.post(ServerPath + '/api/addDocuments', data).then(response => {
						console.log(response)
						dispatch({ type: DOCS_ADDED, payload: response.data.success });
			      return Promise.resolve(response.data.success);
        });
    });
  }
}

export const addMessage = (item) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			recipient: item.recipient,
			message: item.message,
			subject_ref_id: item.subject_ref_id
		};

		console.log(data);
    return axios.post(ServerPath + `/api/addMessage`, data).then(response => {
			console.log(response)
			dispatch({ type: MESSAGES_ADDED, payload: response.data.success });
      return Promise.resolve(response.data.success);
    });
  }
}

export const generateAffidavits = (item) => {
  return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: item.job_ids
		};

		console.log(data);
    return axios.post(ServerPath + `/api/generateAffidavits`, data).then(response => {
			console.log(response)
			dispatch({ type: AFFIDAVITS_ADDED, payload: response.data.success });
			if(item.job_ids.length > 5){
				downloadFile(response.data.zip, "affidavits.zip")
			}else{
				response.data.files.map((x,i) => {
					downloadFile(response.data.files[i], `${data.job_ids[i]}_Affidavit.pdf`)
				})
			}
      return Promise.resolve(response.data.success);
    });
  }
}

export const massPrintAffidavits = (jobs_selected) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: jobs_selected.map(x => x.job_id)
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getPrintAffidavits`, data).then(response => {
			console.log('massPrint[resp]', response.data)
			if (response.data.success) {
				console.log("success")
				const extractFilename = url => url.split('/').pop();
				printJS({printable: response.data.bytes, type: 'pdf', base64: true});
				return Promise.resolve(true);
			}else{
				console.log("failure")
				return Promise.resolve(false);
			}
		})
  }
}

export const massPrintDocsToServe = (jobs_selected) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: jobs_selected.map(x => x.job_id)
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getServeDocs`, data).then(response => {
			console.log('massPrint[resp]', response.data)
			if (response.data.success) {
				console.log("success")
				const extractFilename = url => url.split('/').pop();
				printJS({printable: response.data.bytes, type: 'pdf', base64: true});
				return Promise.resolve(true);
			}else{
				console.log("failure")
				return Promise.resolve(false);
			}
		})
	}
}

const downloadFile = (bytes, fileName) =>  {
    const linkSource = `data:application/zip;base64,${bytes}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

const toBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let res = reader.result.split(',')[1];
            resolve(res);
        };
        reader.onerror = error => reject(error);
    });
};
