import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {toggleBatchActions,togglePDFOn, enterModal, acknowledgeOrders, startReport, getJobs, massPrintAffidavits, generateAffidavits, massPrintDocsToServe} from '../actions'
import { withSnackbar } from 'notistack';

import DragAndDrop from './drag_and_drop'

import Modal from '@material-ui/core/Modal';

import { forwardRef } from 'react';
import {theme_color} from '../tempfile'

class BatchActionView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      batch_toggle_message: "Multi-Selection",
      isShowingMessage: false,
      files: [],
      pdf_button_text: "Open PDF",
      toggleInvoiceMessage: "Switch to Accounting"
    };
  }

  batchClick = () => {
    this.props.toggleBatchActions();
    if(this.props.batch_actions_on){
      this.setState({batch_toggle_message: "Multi-Selection"})
      this.props.cancelSelection()
    }else{
      this.setState({batch_toggle_message: "Single Row Selection"})
    }
  }

  acknowledgeReceipt = () => {
    const job_ids = this.props.jobs.map((row) => {
      if(row.acknowledged === ""){
        return row.job_id;
      }else{
        return -1;
      }
    });
    this.props.acknowledgeOrders(job_ids).then(() => {
      this.props.getJobs({});
    })
  }

  generateNewAffidavits = () => {

    const valid_jobs = this.props.rows_selected.filter(x => {
      if(x.job_status === "Received" || x.job_status === "Assigned"){
        this.props.enqueueSnackbar(`Job ${x.job_id} has not been served or attempted.`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })

    if(valid_jobs.length > 0){
      this.props.generateAffidavits({job_ids: valid_jobs.map((row) => row.job_id)});
    }else{
      this.props.enqueueSnackbar(`None of the jobs selected can generate affidavits!`, {variant: 'error'});
    }
  }

  massPrintAffidavits = () => {

    const filtered_rows_selected = this.props.rows_selected.filter(x => {
      if(x.affidavit_status == "Not Received"){
        this.props.enqueueSnackbar(`Job ${x.job_id} has no affidavit`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })
    if(filtered_rows_selected.length > 0){
      this.props.massPrintAffidavits(filtered_rows_selected).then(res => {
        if(res){
          this.props.enqueueSnackbar(`${filtered_rows_selected.length} affidavits sent to print!`, {variant: 'success'});
        }else{
          this.props.enqueueSnackbar(`Could not print ${filtered_rows_selected.length} affidavits`, {variant: 'error'});
        }
      });
    }else{
      this.props.enqueueSnackbar(`None of the jobs selected had affidavits!`, {variant: 'error'});
    }
  }

  printDocsToServe = () => {
    const filtered_rows_selected = this.props.rows_selected.filter(x => {
      if(x.acknowledged === ""){
        this.props.enqueueSnackbar(`Job ${x.job_id} has not been acknowledged`, {variant: 'error'});
        return false;
      }else{
        return true;
      }
    })
    if(filtered_rows_selected.length > 0){
      this.props.massPrintDocsToServe(filtered_rows_selected).then(res => {
        if(res){
          this.props.enqueueSnackbar(`${filtered_rows_selected.length} jobs sent to print!`, {variant: 'success'});
        }else{
          this.props.enqueueSnackbar(`Could not print ${filtered_rows_selected.length} jobs`, {variant: 'error'});
        }
      });
    }else{
      this.props.enqueueSnackbar(`None of the jobs selected have been acknowledged!`, {variant: 'error'});
    }
  }

  goToReports = () => {
    this.props.startReport();
  }

  render(){

    return (
      <div>
        <div style={contStyles.main}>
          <p style={contStyles.title}>Special Actions:</p>
          {!this.props.loadingScreen && this.props.jobs !== null && this.props.jobs.map((job) => {return job.acknowledged === ""}).filter(x => x).length > 0 ?
            <Button variant="outlined" style={contStyles.button} onClick={this.acknowledgeReceipt}>
              Acknowledge receipt of all new jobs ({this.props.jobs.map((job) => {return job.acknowledged === ""}).filter(x => x).length})
            </Button>
            : null}
          {this.props.jobs !== null ?
            <Button variant="outlined" style={contStyles.button} onClick={this.batchClick}>
              {this.state.batch_toggle_message}
            </Button>
          :null}
          {this.props.rows_selected && this.props.rows_selected.length > 0 ?
          <Button variant="outlined" style={contStyles.button} onClick={this.printDocsToServe}>
            Mass Print Docs to Serve
          </Button>
          :null}
          {this.props.rows_selected && this.props.rows_selected.length > 0 ?
            <Button variant="outlined" style={contStyles.button} onClick={this.generateNewAffidavits}>
              Generate Affidavits
            </Button>
          :null}
          {this.props.rows_selected && this.props.rows_selected.length > 0 ?
            <Button variant="outlined" style={contStyles.button} onClick={this.massPrintAffidavits}>
              Mass Print Affidavits
            </Button>
          :null}
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    currentScreen: state.update.currentScreen,
    pdf_on: state.update.pdf_on,
    rows_selected: state.update.rows_selected,
    loadingScreen: state.update.isUpdating,
    jobs: state.get.jobs
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {toggleBatchActions,togglePDFOn, enterModal, acknowledgeOrders, startReport, getJobs, massPrintAffidavits, generateAffidavits, massPrintDocsToServe}
)(BatchActionView))

const contStyles = {
  main: {
    flex: 1,
    display: 'flex',
    height: 50,
    width: 'auto',
    marginBottom: 30,
    marginTop: 30,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  button: {
    backgroundColor: '#fff',
    margin: 10,
    color: theme_color,
    borderColor: theme_color
  },
  title: {
    color: theme_color,
    fontSize: 14,
    marginLeft: 20
  }
}
