import React, { Component } from 'react';
import {NYC_Zipcodes, GoogleMaps_API_Key} from '../tempfile'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: 520,
  height: 420,
  paddingBottom: 10,
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
         lat: this.props.lat,
         lng: this.props.lng
        }}
      >
        <Marker
          title={'GPS Stamp'}
          name={'GPS'}
          position={{lat: this.props.lat, lng: this.props.lng}} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GoogleMaps_API_Key
})(MapContainer);
