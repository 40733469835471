import {
	CODES_RETURNED,
	JOBS_RETURNED,
  CASES_RETURNED,
  REPORTS_RETURNED,
	MESSAGES_RETURNED,
	DOCUMENTS_RETURNED,
	SOFT_REFRESH
} from './types'

import axios from 'axios';
import {completeUpdate} from '.'
import { ServerPath } from '../tempfile';
import printJS from 'print-js'
import moment from "moment";
const date_format = 'MMMM Do YYYY, h:mm:ss a';

export const getCodes = (filters) => {
	return (dispatch, getState) => {
    return axios.post(ServerPath + `/api/get`, {key: "status"}).then(response => {
			return axios.post(ServerPath + `/api/get`, {key: "priority"}).then(response2 => {
				console.log(response.data.codes)
				console.log(response2.data.codes)
				let codes = {"status": response.data.codes, "priority": response2.data.codes}
        dispatch({ type: CODES_RETURNED, payload: codes });
        return Promise.resolve(true);
			});
    });
  }
}

export const getJobs = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const { codes } = getState().get;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/vendorGetJobs`, data).then(response => {
				console.log(response)
        if (response.data.success) {

					for(let i = 0; i < response.data.jobs.length; i++){
						response.data.jobs[i]["job_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["job_status"]
						})[0].name
						response.data.jobs[i]["affidavit_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["affidavit_status"]
						})[0].name
						response.data.jobs[i]["invoice_status"] = codes.status.filter(obj => {
							return obj.code_id === response.data.jobs[i]["invoice_status"]
						})[0].name
						response.data.jobs[i]["priority"] = codes.priority.filter(obj => {
							return obj.code_id === response.data.jobs[i]["priority"]
						})[0].name
						response.data.jobs[i]["assigned"] = response.data.jobs[i]["assigned"] !== "" ? moment(response.data.jobs[i]["assigned"]).format(date_format) : "";
						response.data.jobs[i]["acknowledged"] = response.data.jobs[i]["acknowledged"] !== "" ? moment(response.data.jobs[i]["acknowledged"]).format(date_format) : "";

						if(response.data.jobs[i].reports !== undefined){
							for(let j = 0; j < response.data.jobs[i].reports.length; j++){
								console.log("REPORT FOUND!")
								response.data.jobs[i].reports[j]["type"] = response.data.jobs[i].reports[j]["type"] === 1 ? "Successful" : "Unsuccessful";
								response.data.jobs[i].reports[j]["completion_date"] = response.data.jobs[i].reports[j]["completion_date"] !== "" ? moment(response.data.jobs[i].reports[j]["completion_date"]).format(date_format) : "";
								response.data.jobs[i].reports[j]["log_date"] = response.data.jobs[i].reports[j]["log_date"] !== "" ? moment(response.data.jobs[i].reports[j]["log_date"]).format(date_format) : "";
							}
						}
					}

					console.log(response.data.jobs)
          dispatch({ type: JOBS_RETURNED, payload: response.data.jobs });
					dispatch(completeUpdate())
          return Promise.resolve(true);
        }else{
          dispatch({ type: JOBS_RETURNED, payload: null });
          return Promise.resolve(false);
        }
    });
  }
}

export const getCases = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/adminGetCases`, data).then(response => {
				console.log(response)
				if (response.data.success) {

					for(let i = 0; i < response.data.cases.length; i++){
						response.data.cases[i]["jurisdiction"] = response.data.cases[i]["jurisdiction"] === 1 ? "State" : "Federal";
					}


          dispatch({ type: CASES_RETURNED, payload: response.data.cases });
          return Promise.resolve(true);
        }else{
          dispatch({ type: CASES_RETURNED, payload: null });
          return Promise.resolve(false);
        }
    });
  }
}

export const getReports = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/vendorGetReports`, data).then(response => {
				console.log('Reports: ', response)
				if (response.data.success) {

					for(let i = 0; i < response.data.reports.length; i++){
						response.data.reports[i]["type"] = response.data.reports[i]["type"] === 1 ? "Successful" : "Unsuccessful";
						response.data.reports[i]["completion_date"] = response.data.reports[i]["completion_date"] !== "" ? moment(response.data.reports[i]["completion_date"]).format(date_format) : "";
						response.data.reports[i]["log_date"] = response.data.reports[i]["log_date"] !== "" ? moment(response.data.reports[i]["log_date"]).format(date_format) : "";
						response.data.reports[i]["military"] = response.data.reports[i]["military"] === "true" ? "Yes" : "No";
					}

          dispatch({ type: REPORTS_RETURNED, payload: response.data.reports });
					dispatch(completeUpdate())
          return Promise.resolve(true);
        }else{
          dispatch({ type: REPORTS_RETURNED, payload: null });
          return Promise.resolve(false);
        }
    });
  }
}


export const getMessages = (filters) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			filters: filters
		};
    return axios.post(ServerPath + `/api/getMessages`, data).then(response => {
				console.log(response)
				if (response.data.success) {
          dispatch({ type: MESSAGES_RETURNED, payload: response.data.messages });
          return Promise.resolve(true);
        }else{
          dispatch({ type: MESSAGES_RETURNED, payload: null });
          return Promise.resolve(false);
        }
    });
  }
}

export const getDocuments = (urls) => {
	return (dispatch, getState) => {
    const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			urls: urls
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getDocumentsByURL`, data).then(response => {
				console.log(response)
				if (response.data.success) {
            const d = response.data;

            const documents = urls.map((url, i) => {
                return {
                    filename: d.names[i],
                    bytes: d.bytes[i],
                    type: (d.types && d.types[i]) || 'application/pdf'
                };
            });
          dispatch({ type: DOCUMENTS_RETURNED, payload: documents });
					for(let i = 0; i < documents.length; i++){
						downloadFile(documents[i].bytes, documents[i].filename)
					}
          return Promise.resolve(true);
        }else{
          dispatch({ type: DOCUMENTS_RETURNED, payload: null });
          return Promise.resolve(false);
        }
    });
  }
}

export const massPrintDocsToServe = (jobs_selected) => {
	return (dispatch, getState) => {
		const { apiToken } = getState().auth;
		const data = {
			token: apiToken,
			job_ids: jobs_selected.map(x => x.job_id)
		};

		console.log(data)
    return axios.post(ServerPath + `/api/getServeDocs`, data).then(response => {
			console.log('massPrint[resp]', response.data)
			if (response.data.success) {
				console.log("success")
				const extractFilename = url => url.split('/').pop();
				printJS({printable: response.data.bytes, type: 'pdf', base64: true});
				return Promise.resolve(true);
			}else{
				console.log("failure")
				return Promise.resolve(false);
			}
		})
	}
}


export const softRefresh = (val) => {
	return (dispatch) => {
		console.log('val', val);
  	dispatch({ type: SOFT_REFRESH, payload: val});
  }
}

const downloadFile = (bytes, name) =>  {
    const linkSource = `data:application/pdf;base64,${bytes}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}
