import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Box,
    CircularProgress
} from '@material-ui/core';

import printJS from 'print-js'
import { Document, Page, pdfjs } from 'react-pdf'
import {getDocuments} from '../actions'
import {theme_color} from '../tempfile'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFDisplay extends Component {

  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      doc: null,
    };

  }

  componentDidMount(){
    setTimeout(() => {
      console.log("mount timeout")
      this.props.getDocuments([this.props.doc_url]);
      const check = setInterval(() => {
        if(this.props.documents !== null && this.props.documents.length > 0){
          clearInterval(check);
          this.setState({doc: this.props.documents[0]});
        }
      }, 100);
    }, 600);
  }

  goToPrevPage = () => {
    if(this.state.pageNumber > 1){
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    }
  }
  goToNextPage = () => {
    if(this.state.pageNumber < this.state.numPages){
      this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
    }
  }

  print = () => {
    printJS({printable: this.state.doc.bytes, type: 'pdf', base64: true});
  }

  onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
  }

  render(){

    const { pageNumber, numPages } = this.state;
    return (
      <div>
        <script src="print.js"></script>
        {this.state.doc != null ?
          <div>
            <nav>
              <Button style={contStyles.button} onClick={this.goToPrevPage}>Prev</Button>
              <Button style={contStyles.button} onClick={this.goToNextPage}>Next</Button>
              <Button style={contStyles.button} onClick={this.print}>Print</Button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </nav>

            <div style={{ width: this.props.width }}>
              <Document
                file={`data:application/pdf;base64,${this.state.doc.bytes}`}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} width={this.props.width} />
              </Document>
            </div>
          </div>
          :
          <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}
          >
              <CircularProgress></CircularProgress>
          </Box>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    pdf_on: state.update.pdf_on,
    documents: state.get.documents,
    doc_url: state.update.doc_url
  }
}

export default connect(
  mapStateToProps,
  {getDocuments}
)(PDFDisplay)

const contStyles = {
  main: {
    flex: 1,
    display: 'flex',
    height: 50,
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#d3d3d3',
    borderWidth: 2,
    borderRadius: 10,
    marginBottom: 30,
    backgroundColor: theme_color,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  button: {
    backgroundColor: '#fff',
    margin: 10,
    width: 200,
    height: 50,
    color: theme_color,
    border: '2px solid #2196f3'

  },
  title: {
    color: '#fff',
    fontSize: 14,
    marginLeft: 20
  }
}
