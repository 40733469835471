import {
  START_UPDATE,
  UPDATE_COMPLETED,
  EMAIL_SENT,
  TOGGLE_BATCH_ACTIONS,
  TOGGLE_PDF,
  ENABLE_BATCH_ACTIONS,
  UPDATE_SELECTED_ROWS,
	ENABLE_AFFIDAVIT_ACTIONS,
  ENABLE_DOC_UPLOAD_ACTIONS,
  ENABLE_DOC_DISPLAY_MODAL,
	DISABLE_ACTIONS,
  ACKNOWLEDGE_JOBS,
  START_REPORT,
  ENABLE_MESSAGE_MODAL,
  ENABLE_JOB_SELECTION
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	isUpdating: false,
  currentScreen: 0,
  emailSent: false,
  batch_actions_on: false,
  pdf_on: false,
  filters: {},
  rows_selected: null,
  doc_upload_menu_on: false,
  affidavit_menu_on: false,
  current_jobs: null,
  doc_url: null,
  reportUsesSelection: false,
  message_modal_on: false,
  job_selection_on: false
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
    case START_UPDATE:
      console.log("updating")
      if(action.payload == 0){
        console.log("this is called")
        return { ...state, isUpdating: true, currentScreen: action.payload, reportUsesSelection: false, rows_selected: null, current_jobs: null };
      }else{
        return { ...state, isUpdating: true, currentScreen: action.payload };

      }
    case UPDATE_COMPLETED:
      console.log("not updating")
      return { ...state, isUpdating: false };
    case START_REPORT:
      console.log("report uses is true")
      return { ...state, reportUsesSelection: true, currentScreen: 1}
    case EMAIL_SENT:
      return { ...state, emailSent: action.payload };
    case TOGGLE_BATCH_ACTIONS:
      return { ...state, batch_actions_on: !state.batch_actions_on };
    case TOGGLE_PDF:
      return { ...state, pdf_on: !state.pdf_on };
    case UPDATE_SELECTED_ROWS:
      return { ...state, rows_selected: action.payload };
    case ENABLE_BATCH_ACTIONS:
      return { ...state, batch_edit_menu_on: true };
    case ENABLE_AFFIDAVIT_ACTIONS:
      console.log("affidavit on for ", action.payload);
      return { ...state, affidavit_menu_on: true, doc_url: action.payload };
    case ENABLE_DOC_UPLOAD_ACTIONS:
      return { ...state, doc_upload_menu_on: true, current_jobs: action.payload };
    case DISABLE_ACTIONS:
      if(action.payload !== null && action.payload !== undefined){
        if(action.payload == "docs"){
          return { ...state, doc_upload_menu_on: false }
        }
      }else{
        return { ...state, current_jobs: null, current_clients: null, job_selection_on: false, message_modal_on: false, products_modal_on: false, job_charges_modal_on: false, batch_edit_menu_on: false, invoice_menu_on: false, payment_menu_on: false, statement_menu_on: false, affidavit_menu_on: false, efiling_menu_on: false, doc_upload_menu_on: false};
      }
    case ACKNOWLEDGE_JOBS:
      return { ...state };
    case ENABLE_MESSAGE_MODAL:
      return { ...state, message_modal_on: true}
    case ENABLE_JOB_SELECTION:
      return { ...state, job_selection_on: true, rows_selected: action.payload}
    default:
			return { ...state };
  }
}
