import React, { Component } from 'react';
import { connect } from 'react-redux'
import {login} from '../../actions'

import styled from 'styled-components';
import {app_user, theme_color} from '../../tempfile'
import * as legalex_logo from '../../images/legalex_logo.png';
import * as nh_logo from '../../images/nh_logo.png';
import * as backdropImg from '../../images/backdrop.png';
import Typography from '@material-ui/core/Typography';
import {
    Button,
    Box,
    Link,
    TextField,
    Container,
    CircularProgress
} from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';

const base = props => <Typography variant="h4" {...props} />;
export const Title = styled(base)`
    font-weight: 400;
    color: #333;
`;

const base2 = props => <Typography variant="subtitle1" {...props} />;
export const Subtitle = styled(base2)`
    color: #999;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
`;

const LogoWrapper = styled.img`
    width: ${props => (props.size === 'sm' ? '13rem' : '23rem')};
    padding: 0.5rem 0;
`;

export const Logo = ({ size }) => <LogoWrapper size={size} src={app_user == "nh" ? nh_logo : legalex_logo} />;


const Wrapper = styled.div`
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: stretch;
`;

const Backdrop = styled.div`
    background-image: url(${backdropImg});
    background-size: cover;
    background-position: center;
    flex: 1;
`;

const Main = styled.main`
    flex: 1;
    background-color: white;
`;

const validation = Yup.object({
    username: Yup.string('Enter your username')
        .required('Username is required')
        .min(8, 'Username must be at least 8 characters')
        .max(50, 'Username must be at most 50 characters'),
    password: Yup.string('Enter your password')
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password must be at most 20 characters')
});

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstTimeLogin: false,
      invalidCredentials: false
    };
  }

  render(){

    const onSubmit = (values, actions) => {
        this.props.login(values)
        .then((success) => {
          if(success){
            this.props.history.push('/home');
          }else{
            actions.setStatus({invalidCredentials: true});
            this.setState({invalidCredentials: true})
          }
        }).finally(() => {
            actions.setSubmitting(false);
        });
    };

    return (
        <Wrapper>
            <Backdrop />
            <Main>
            <Container maxWidth="sm">
                <Box py={10}>
                    <Box mb={9}>
                        <Logo />
                    </Box>
                    {this.state.firstTimeLogin ? (
                        <>
                            <Title>Express Vendor Portal Signup</Title>
                            <Subtitle>
                                Define your credentials and enter your Vendor ID so
                                we can match them to your records.
                            </Subtitle>
                        </>
                    ) : (
                        <>
                            <Title>Log In to our Vendor Portal</Title>
                            <Subtitle>
                                Discover the potential.
                            </Subtitle>
                        </>
                    )}
                    <Formik
                        render={props =>

                          <form onSubmit={props.handleSubmit}>
                              {props.status && this.state.invalidCredentials && (
                                  <Box
                                      mt={5}
                                      display="flex"
                                      alignItems="center"
                                      color="error.main"
                                  >
                                      <Box mr={1}>
                                          <Error />
                                      </Box>
                                      Invalid login credentials
                                  </Box>
                              )}

                              <TextField
                                  name="username"
                                  placeholder="Username"
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="username"
                                  value={props.values.username}
                                  helperText={props.touched.username && props.errors.username}
                                  error={props.touched.username && !!props.errors.username}
                                  onChange={props.handleChange}
                              />

                              <TextField
                                  name="password"
                                  placeholder="Password"
                                  fullWidth
                                  margin="normal"
                                  type="password"
                                  variant="outlined"
                                  autoComplete="current-password"
                                  value={props.values.password}
                                  helperText={props.touched.password && props.errors.password}
                                  error={props.touched.password && !!props.errors.password}
                                  onChange={props.handleChange}
                              />

                              {this.state.firstTimeLogin ? (
                                  <>
                                      <TextField
                                          name="clientId"
                                          placeholder="Vendor ID (Emailed to you)"
                                          fullWidth
                                          margin="normal"
                                          variant="outlined"
                                          type="number"
                                          value={props.values.clientId}
                                          helperText={props.touched.clientId && props.errors.clientId}
                                          error={props.touched.clientId && !!props.errors.clientId}
                                          onChange={props.handleChange}
                                      />

                                      <Box mt={4} mb={3}>
                                          <Link
                                              to="/login"
                                              component={RouterLink}
                                              onClick={() => this.setState({firstTimeLogin: false})}
                                              style={{color: theme_color}}
                                          >
                                              Already have account?
                                          </Link>
                                      </Box>
                                  </>
                              ) : (
                                  <Box mt={4} mb={3}>
                                      <Link
                                          to="/login"
                                          component={RouterLink}
                                          onClick={() => this.setState({firstTimeLogin: true})}
                                          style={{color: theme_color}}
                                      >
                                          First time user?
                                      </Link>
                                  </Box>
                              )}

                              <Button
                                  size="large"
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  disabled={props.isSubmitting}
                                  style={{backgroundColor: theme_color}}
                              >
                                  <Box display="flex">
                                      {props.isSubmitting && (
                                          <CircularProgress size={24} color="primary" />
                                      )}
                                      {!props.isSubmitting && <span>Log in</span>}
                                  </Box>
                              </Button>
                          </form>

                        }
                        initialValues={{
                            username: '',
                            password: '',
                            clientId: null
                        }}
                        validationSchema={validation}
                        onSubmit={(values, actions) => onSubmit(values, actions)}
                    />
                </Box>
            </Container>
            </Main>
        </Wrapper>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken
  }
}

export default connect(
  mapStateToProps,
  {login}
)(Login)
