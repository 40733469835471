import React, { Component } from 'react';
import { connect } from 'react-redux'
import {addReports, getJobs, startUpdate, completeUpdate, generateAffidavits} from '../../actions'
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';

import styled from 'styled-components';
import {app_user, theme_color} from '../../tempfile'
import * as legalex_logo from '../../images/legalex_logo.png';
import * as nh_logo from '../../images/nh_logo.png';
import * as backdropImg from '../../images/backdrop.png';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { withSnackbar, closeSnackbar } from 'notistack';


import {
    Button,
    Box,
    Link,
    TextField,
    Container,
    CircularProgress,
    MenuItem,
    Select,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TimePicker from 'react-time-picker';
import Select2 from 'react-select';
import CreatableSelect from 'react-select/creatable';

import Error from '@material-ui/icons/Error';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';

import { geolocated } from "react-geolocated";
import MapContainer from 'components/google_maps';

import { NYC_Zipcodes } from '../../tempfile';


var dateFormat = require('dateformat');
var moment = require('moment');

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

const base = props => <Typography variant="h4" {...props} />;
export const Title = styled(base)`
    font-weight: 300;
    color: #333;
`;

const base2 = props => <Typography variant="subtitle1" {...props} />;
export const Subtitle = styled(base2)`
    color: #999;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
`;

const BlueCheckbox = withStyles({
  root: {
    color: theme_color,
    '&$checked': {
      color: theme_color,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const LogoWrapper = styled.img`
    width: ${props => (props.size === 'sm' ? '13rem' : '23rem')};
    padding: 0.5rem 0;
`;

const today = new Date();
console.log("today is ", today instanceof Date)

export const Logo = ({ size }) => <LogoWrapper size={size} src={app_user == "nh" ? nh_logo : legalex_logo} />;

const Wrapper = styled.div`
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: stretch;
`;

const Backdrop = styled.div`
    background-image: url(${backdropImg});
    background-size: cover;
    background-position: center;
    flex: 1;
`;

const Main = styled.main`
    flex: 1;
    background-color: white;
    margin-top: 80;
`;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const subtypes = ["Authorized", "Business", "Corporation", "Government Agency", "Mail", "Personal/Individual", "Posted", "Registered Agent", "Secretary of State", "Substitute Service- Abode", "Substitute Service- Business", "Substitute Service- Personal", "Suitable Age Person"]
const ethnicities = ["African American", "Asian American", "Caucasian", "Hispanic", "Latino", "Middle Eastern", "Native American", "Native Hawaiian"]
const genders = ["Male", "Female"]
const badoptions = ["Bad Address", "Non-Service", "Unsuccessful Attempt"]
const hairtypes = ["Bald", "Black", "Blond", "Brown", "Gray", "Red", "White"]
const eyecolor = ["Amber", "Black", "Blue", "Brown", "Gray", "Green", "Hazel"]
const weights = ["100-130", "130-160", "160-180", "180+"]
const heights = ["Under 5'", "5'0\"-5'2\"", "5'2\"-5'4\"","5'4\"-5'6\"","5'6\"-5'8\"","5'8\"-6'0\"","Over 6'"]
const affidavit_types = ["Nationwide", "NY", "NJ", "CA", "TX"]
const relationships = ["Aunt", "Boyfriend", "Brother", "Cousin", "Daughter", "Father", "Girlfriend", "Grandfather", "Grandmother", "Husband", "Monther", "Partner", "Managing Agent", "Nephew", "Niece", "Registered Agent", "Sister", "Son", "Uncle", "Wife"]
const timezones = [
  {value: "-05:00", label: "EST"},
  {value: "-04:00", label: "EDT"},
  {value: "-06:00", label: "CST"},
  {value: "-05:00", label: "CDT"},
  {value: "-07:00", label: "MST"},
  {value: "-06:00", label: "MDT"},
  {value: "-08:00", label: "PST"},
  {value: "-07:00", label: "PDT"}
]

class SubmitReport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      invalidForm: false,
      selectedJobs: [],
      reportType: "Select",
      caption: "Select",
      completion: today.toLocaleDateString("en-US"),
      time: today.getHours() + ":" + today.getMinutes(),
      gender: "",
      ethnicity: "",
      hair: "",
      eyes: "",
      relationship: "",
      checkedA: true,
      military: "No Response",
      affidavitType: "Nationwide",
      comments: "",
      height: "",
      weight: "",
      name: "",
      age: "",
      timezone: {value: "-04:00", label: "EDT"},
      gps_opt_out: false,
      server_name: "",
      server_license: ""
    };
  }

  changeEthnicity = (event) => this.setState({ethnicity: event.value})
  changeTime = time => this.setState({ time: time })
  changeHair = (event) => this.setState({hair: event.value})
  changeEyes = (event) => this.setState({eyes: event.value})
  changeRelationship = (event) => this.setState({relationship: event.value})
  changeGender = (event) => this.setState({gender: event.value})
  handleDateChange = (event) => this.setState({completion: new Date(event).toLocaleDateString("en-US")})
  changeReason = (event) => {
    this.setState({caption: event.value})
    if(event.value == "Suitable Age Person" || event.value == "Personal/Individual" || event.value == "Mail"){
      this.setState({military: "No Response"})
    }else{
      this.setState({military: "N/A"})
    }
  }
  changeSelection = (event) => this.setState({selectedJobs: event.target.value})
  changeReportType = (event) => this.setState({reportType: event.value})
  handleCheckboxChange = (event) => this.setState({checkedA: !this.state.checkedA})
  handleCheckboxNegChange = (event) => this.setState({military: "No"})
  handleCheckboxPosChange = (event) => this.setState({military: "Yes"})
  changeWeight = (event) => this.setState({weight: event.value})
  changeHeight = (event) => this.setState({height: event.value})
  changeComments = (event) => this.setState({comments: event.target.value})
  changeName = (event) => this.setState({name: event.target.value})
  changeAge = (event) => this.setState({age: event.target.value})
  changeTimezone = (event) => this.setState({timezone: event})
  changeGPSOptOut = (event) => this.setState({gps_opt_out: !this.state.gps_opt_out})
  changeServerName = (event) => this.setState({server_name: event.target.value})
  changeServerLicense = (event) => this.setState({server_license: event.target.value})

  componentDidMount(){
    const checkSelection = setInterval(() => {
      if(this.props.reportUsesSelection){
				clearInterval(checkSelection);
        this.setState({selectedJobs: this.props.rows_selected.map((job) => {return job.job_id})})
      }
    }, 500);

    const checkLicense = setInterval(() => {
      if(this.props.vendor){
				clearInterval(checkLicense);
        this.setState({server_name: this.props.vendor.def_server, server_license: this.props.vendor.def_license})
      }
    }, 500);
  }

  render(){

    const onSubmit = (values, actions) => {

        let gps_location = "";

        if(!this.state.gps_opt_out){
          if(!this.props.coords){
            this.props.enqueueSnackbar(`GPS Coordinate not available`, {variant: 'error'})
            this.props.enqueueSnackbar(`If you're in NYC, you are required to get GPS coordinates`)
            this.props.enqueueSnackbar(`If you're NOT in NYC, you can choose to waive the requirement`)
            return;
          }
          gps_location = `${this.props.coords.latitude},${this.props.coords.longitude}`
        }


        let completionDate = dateFormat(this.state.completion, "yyyy-mm-dd");
        const my_time = this.state.time.length == 4 ? '0' + this.state.time : this.state.time;
        console.log('input: ', completionDate + 'T' + my_time + this.state.timezone.value)
        completionDate = moment.utc(completionDate + 'T' + my_time + this.state.timezone.value).format()
        console.log('completion: ', completionDate.substring(0, completionDate.length - 4) + 'Z');
        console.log('address_id: ', this.props.jobs.filter((job) => {return job.job_id == this.state.selectedJobs[0]})[0].address_id);

        this.props.addReports({
          ...this.state,
          job_ids: this.state.selectedJobs,
          type: this.state.reportType,
          gps_location: gps_location,
          completion: completionDate.substring(0, completionDate.length - 4) + 'Z',
          address_id: this.props.jobs.filter((job) => {return job.job_id == this.state.selectedJobs[0]})[0].address_id,
        })
        .then((success) => {
          if(success){
            this.props.enqueueSnackbar(`Attempts submitted for ${this.state.selectedJobs.length} job${this.state.selectedJobs.length>1 ? 's':''}!`, {variant: 'success'})
            this.props.startUpdate(0);
            this.props.getJobs();
            if(this.state.checkedA){
              const states = this.state.selectedJobs.map((row) => this.props.jobs.filter(j => j.job_id == row)[0].state.toLowerCase());
              this.props.generateAffidavits({job_ids: this.state.selectedJobs}).then(res => {
                if(res){
                  this.props.enqueueSnackbar(`Successfully generated ${this.state.selectedJobs.length} affidavit${this.state.selectedJobs.length>1 ? 's':''}!`, {variant: 'success'})
                }else{
                  this.props.enqueueSnackbar(`Failed to generate affidavits`, {variant: 'error'})
                }
              });
            }
            console.log("success");
          }else{
            this.props.enqueueSnackbar(`Could not submit attempts`, {variant: 'error'})
            actions.setStatus({invalidForm: true});
            this.setState({invalidForm: true})
          }
        }).finally(() => {
            actions.setSubmitting(false);
        });
    };

    console.log("jobs selected")
    console.log(this.state.selectedJobs)

    return (
        <Wrapper>
            <Main>
            <Container maxWidth="sm">
                <Box py={10}>
                    <Title style={{color: theme_color}}>Submit Attempts Now</Title>
                    <Formik
                        wrap
                        render={props =>
                          <form onSubmit={props.handleSubmit}>
                            {console.log(props)}
                              {props.status && this.state.invalidForm && (
                                  <Box
                                      mt={5}
                                      display="flex"
                                      alignItems="center"
                                      color="error.main"
                                  >
                                      <Box mr={1}>
                                          <Error />
                                      </Box>
                                      Your form is incomplete or has invalid entries.
                                  </Box>
                              )}

                              <p>Orders to report on</p>
                              <Select
                                multiple
                                value={this.state.selectedJobs}
                                onChange={this.changeSelection}
                                renderValue={selected => (
                                  <div style={contStyles.chips}>
                                    {selected.map(value => {
                                      let next = this.props.jobs.filter(j => j.job_id == value)[0];
                                      return <Chip key={value} label={`${next.rec_name}: ${next.job_id}`} style={contStyles.chip} />
                                    })}
                                  </div>
                                )}
                                fullWidth
                              >
                                {this.props.jobs!== null ?
                                  this.props.jobs.filter((job) => {
                                    if(this.state.selectedJobs.length == 0){
                                      return true;
                                    }else{
                                      for(let i = 0; i < this.props.jobs.length; i++){
                                        if(this.state.selectedJobs.includes(this.props.jobs[i].job_id) && job.address === this.props.jobs[i].address && job.status !== "Served" && job.acknowledged !== ""){
                                          return true;
                                        }
                                      }
                                    }
                                    return false;
                                  }).map(job => (
                                  <MenuItem key={job.job_id} value={job.job_id}>
                                    {job.rec_name}: {job.job_id}
                                  </MenuItem>
                                )): null}
                              </Select>
                              <p>Location of service</p>
                              <Button style={{marginBottom: 10}} variant="outlined" onClick={this.props.locationButtonAction}>
                                Refresh GPS location
                              </Button>
                              {!this.props.isGeolocationAvailable ?
                                  <div>Your browser does not support Geolocation</div>
                                 : !this.props.isGeolocationEnabled ?
                                    <div>Geolocation is not enabled</div>
                                 : this.props.coords ?
                                    <div style={{ height: 400, width: 525 }}>
                                      <p>Latitude: {this.props.coords.latitude.toFixed(3)} | Longitude: {this.props.coords.longitude.toFixed(3)} | Horizontal Accuracy: {this.props.coords.accuracy} ft</p>
                                      <MapContainer lat={this.props.coords.latitude} lng={this.props.coords.longitude}/>
                                    </div>
                                 :
                                  <div>Getting the location data&hellip; </div>
                              }
                              {/* This gives servers the option to waive the gps requirement when they're not in NYC and have no signal */}
                              {!this.props.isGeolocationAvailable && this.state.selectedJobs.length > 0 && !NYC_Zipcodes.includes(this.props.jobs.filter((job) => {return job.job_id == this.state.selectedJobs[0]})[0].zipcode) ?
                                <div>
                                  <FormControlLabel
                                    control={
                                      <BlueCheckbox checked={this.state.gps_opt_out} onChange={this.changeGPSOptOut}/>
                                    }
                                    label="Waive GPS coordinate requirement (No signal available)"
                                  />
                                </div>
                              : null}
                              <p style={{marginTop: 100}}>Attempt Type</p>
                              <Select2 styles={selectStyles}
                                  value={{value: this.state.reportType, label: this.state.reportType}}
                                  onChange={this.changeReportType}
                                  fullWidth
                                  options={[{value:"Successful" , label: "Successful"}, {value:"Unsuccessful" , label: "Unsuccessful"}]}
                               />
                              {this.state.reportType === "Successful" ?
                                <div>
                                <p>Subtype</p>
                                <Select2 styles={selectStyles}
                                  value={{value: this.state.caption, label: this.state.caption}}
                                  onChange={this.changeReason}
                                  fullWidth
                                  options={subtypes.map(t => {return {value: t, label: t}})}
                                />
                                <p>Recipient details</ p>
                                <TextField
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="name"
                                    value={this.state.name}
                                    onChange={this.changeName}
                                />

                                <TextField
                                    name="age"
                                    label="Age"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="age"
                                    value={this.state.age}
                                    onChange={this.changeAge}
                                />

                                <p>Ethnicity</p>
                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.ethnicity, label: this.state.ethnicity}}
                                  onChange={this.changeEthnicity}
                                  fullWidth
                                  options={ethnicities.map(e => {return {value: e, label: e}})}
                                />

                                <p>Gender</p>
                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.gender, label: this.state.gender}}
                                  onChange={this.changeGender}
                                  fullWidth
                                  options={genders.map(g => {return {label: g, value: g}})}
                                />

                                <p>Weight</p>
                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.weight, label: this.state.weight}}
                                  onChange={this.changeWeight}
                                  fullWidth
                                  options={weights.map(w => {return {label: w, value: w}})}
                                />

                                <p>Height</p>
                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.height, label: this.state.height}}
                                  onChange={this.changeHeight}
                                  fullWidth
                                  options={heights.map(h => {return {label: h, value: h}})}
                                />

                                <p>Hair Color</p>
                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.hair, label: this.state.hair}}
                                  onChange={this.changeHair}
                                  fullWidth
                                  options={hairtypes.map(x => {return {label: x, value: x}})}
                                />

                                <p>Eye Color</p>

                                <CreatableSelect styles={selectStyles}
                                  value={{label: this.state.eyes, value: this.state.eyes}}
                                  onChange={this.changeEyes}
                                  fullWidth
                                  options={eyecolor.map(x => {return {label: x, value: x}})}
                                />

                                <p>Relationship</p>

                                <CreatableSelect styles={selectStyles}
                                  value={{value: this.state.relationship, label: this.state.relationship}}
                                  onChange={this.changeRelationship}
                                  fullWidth
                                  options={relationships.map(x => {return {label: x, value: x}})}
                                />

                                {this.state.caption == "Suitable Age Person" || this.state.caption == "Personal/Individual" || this.state.caption == "Mail" ?
                                  <div>
                                    <p>Military: I asked the person spoken to whether</p>
                                    <p>defendant was in active military service of the United States</p>
                                    <p>or of the State of court filing in any capacity whatever and</p>

                                    <FormControlLabel
                                      control={
                                        <BlueCheckbox checked={this.state.military == "No"} onChange={this.handleCheckboxNegChange}/>
                                      }
                                      label="received a negative reply."
                                    />
                                    <FormControlLabel
                                      control={
                                        <BlueCheckbox checked={this.state.military == "Yes"} onChange={this.handleCheckboxPosChange}/>
                                      }
                                      label="received a positive reply."
                                    />
                                  </div>
                                :null}

                                </div>
                              : null}
                              {this.state.reportType === "Unsuccessful" ?
                                <div>
                                <p>Reason</p>
                                <Select2 styles={selectStyles}
                                  value={{label: this.state.caption, value: this.state.caption}}
                                  onChange={this.changeReason}
                                  fullWidth
                                  options={badoptions.map(x => {return {label: x, value: x}})}
                                />
                                </div>
                              : null}
                              <div>
                                <TextField
                                    name="comments"
                                    label="Comments"
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="comments"
                                    style={{marginTop: 20}}
                                    value={this.state.comments}
                                    onChange={this.changeComments}
                                />

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                      name="completion"
                                      variant="inline"
                                      inputVariant="outlined"
                                      label="Completion Date"
                                      format="MM/dd/yyyy"
                                      margin="normal"
                                      autoComplete="completion"
                                      maxDate={today}
                                      fullWidth
                                      autoOk
                                      value={this.state.completion}
                                      onChange={this.handleDateChange}
                                      error={false}
                                  />
                                </MuiPickersUtilsProvider>
                                <Select2 styles={selectStyles}
                                  value={{value: this.state.timezone.value, label: this.state.timezone.label}}
                                  onChange={this.changeTimezone}
                                  fullWidth
                                  options={timezones}
                                />
                              <div style={{marginTop: 15}}>
                                <TimePicker
                                  onChange={this.changeTime}
                                  value={this.state.time}
                                  clockIcon={null}
                                  clearIcon={null}
                                />
                              </div>
                              </div>
                              <FormControlLabel
                                control={
                                  <BlueCheckbox checked={this.state.checkedA} onChange={this.handleCheckboxChange}/>
                                }
                                label="Generate Affidavits upon submitting attempt"
                              />
                              <TextField
                                  name="server_name"
                                  label="Server Name"
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="server_name"
                                  value={this.state.server_name}
                                  onChange={this.changeServerName}
                              />
                              <TextField
                                  name="server_license"
                                  label="Server License"
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="server_license"
                                  value={this.state.server_license}
                                  onChange={this.changeServerLicense}
                              />

                              <Button
                                  size="large"
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  disabled={props.isSubmitting || this.state.selectedJobs.length < 1}
                                  style={{backgroundColor: theme_color, marginTop: 30}}
                              >
                                  <Box display="flex">
                                      {props.isSubmitting && (
                                          <CircularProgress size={24} color="primary" />
                                      )}
                                      {!props.isSubmitting && <span>Submit attempt for {this.state.selectedJobs.length} jobs.</span>}
                                  </Box>
                              </Button>
                          </form>

                        }
                        onSubmit={(values, actions) => onSubmit(values, actions)}
                    />
                </Box>
            </Container>
            </Main>
        </Wrapper>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    apiToken: state.auth.apiToken,
    jobs: state.get.jobs,
    rows_selected: state.update.rows_selected,
    reportUsesSelection: state.update.reportUsesSelection,
    vendor: state.auth.vendor
  }
}

export default withSnackbar(connect(
  mapStateToProps,
  {addReports, getJobs, startUpdate, completeUpdate, generateAffidavits}
)(geolocated({
    positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: Infinity,
    },
    watchPosition: true,
    userDecisionTimeout: 5000,
    suppressLocationOnMount: false,
})(SubmitReport)))

const contStyles = {
  chips: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: theme_color,
    borderColor: theme_color
  },
}
