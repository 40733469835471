import {
	LOGIN_SUCCEEDED,
  LOGIN_FAILED,
	VENDOR_LOADED,
	LOGOUT_SUCCEEDED,
	LOGOUT_FAILED
} from '../actions/types'

import React from 'react';

const INITIAL_STATE = {
	apiToken: null,
	vendor: null,
	login_success: false,
};

export default (state = INITIAL_STATE, action) => {
	switch(action.type) {
		case LOGIN_SUCCEEDED:
			console.log("login succeeded")
			return { ...state, apiToken: action.payload, login_success: true };
    case LOGIN_FAILED:
			console.log("login failed")
  		return { ...state, login_success: false };
		case VENDOR_LOADED:
			return { ...state, vendor: action.payload };
		case LOGOUT_SUCCEEDED:
			return { ...state, apiToken: null };
		case LOGOUT_FAILED:
			return { ...state, apiToken: null };
		default:
			return { ...state };
  }
}
