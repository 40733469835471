import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import {toggleBatchActions, togglePDFOn, enterModal, startReport, updateRowSelection} from '../actions'

import DragAndDrop from './drag_and_drop'

import Modal from '@material-ui/core/Modal';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import { forwardRef } from 'react';

import PDFDisplay from "./pdf_display";

import UploadDialog from "components/upload_dialog"

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';

import {
    TableIcons,
    UploadIcon,
    InvoiceIcon,
    AffidavitIcon,
    BellIcon
} from './table_icons';

import Divider from '@material-ui/core/Divider';

class JobModals extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      dnd_message: "Drop Here! :)",
      index: 0,
      has_options: false
    };
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }


  handleClose = () => {
    this.setState({
      dropdown_value: "",
      input_value: "",
      keys: [],
      message: "",
      buttonMessage: "Make changes",
      files: [],
      affidavit_file: null,
      dnd_message: "Drop Here! :)",
    })
    this.props.enterModal("dismiss");
  }

  handleDrop = (files) => {
    this.setState({files: files[0], dnd_message: "File Selected"})
  }

  handleSelectChange = (event) => {
    this.setState({dropdown_value: event.target.value});
  };


  handleInputChange = (event) => {
    this.setState({input_value: event.target.value});
  }

  submitAffidavit = (event) => {
    console.log("submit affidavit")
  }

  uploadAffidavit = (event) => {
    this.props.enterModal("docs", this.props.current_jobs);
  }

  makeEdit = () => {
    if(this.state.buttonMessage == "Make changes"){
      this.setState({buttonMessage: "Confirm", message: `You are about to change column ${this.state.dropdown_value} to value \"${this.state.input_value}\" on ${this.props.rows_selected.length} rows. Do you wish to continue?`})
    }else{
      const changes = [{[this.state.dropdown_value]: this.state.input_value}]
      const ids = this.props.rows_selected.map((row) =>{return row[this.props.id_column]})
      this.props.editData(changes, ids)
    }
  }

  handleKeyPress = (event) => {
    if(event.which === 37){       // Left
      console.log('Left click!')
    }else if(event.which === 39){ // Right
      console.log('Right click')
    }
  }

  goToReports = (event) => {
    this.props.startReport();
    this.handleClose();
  }

  addOtherJobsAtAddress = (event) => {
    const all_jobs_at_address = this.props.jobs.filter(job => job.address === this.props.rows_selected[0].address);
    console.log(all_jobs_at_address)
    this.props.updateRowSelection(all_jobs_at_address)
    this.props.startReport();
    this.handleClose();
  }

  getNumJobsAtAddress = () => {
    return this.props.jobs && this.props.rows_selected && this.props.rows_selected[0] ? this.props.jobs.filter(job => job.address && job.address === this.props.rows_selected[0].address && job.job_status !== "Served").length : 0;
  }


  render(){

    console.log("job selected")
    console.log(this.props.current_jobs)

    return(
      <div>

        {/* Upload Docs */}
        <UploadDialog type="job"/>

        {/* PDF Display */}
        <Modal
            open={this.props.affidavit_menu_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperXL}>
              <h2>Affidavit</h2>
              <PDFDisplay width={700}/>
            </div>
        </Modal>

        {/* Start Report */}
        <Modal
            open={this.props.job_selection_on}
            onClose={this.handleClose}
            style={contStyles.container}
          >
            <div style={contStyles.paperMini}>
              <h2>Start a new attempt</h2>
              <Button variant="outlined" style={{width: 300, marginTop: 50}} onClick={this.goToReports}>
                Continue with this job
              </Button>
              {this.getNumJobsAtAddress() > 1 ?
                <Button variant="outlined" style={{width: 300, marginTop: 50}} onClick={this.addOtherJobsAtAddress}>
                  Continue adding all {this.getNumJobsAtAddress()} jobs at this address.
                </Button>
                :
                <p>No additional jobs at this address</p>
              }

            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdf_on: state.update.pdf_on,
    mode: state.update.billing_chart,
    rows_selected: state.update.rows_selected,

    jobs: state.get.jobs,
    current_jobs: state.update.current_jobs,
    upload_menu_on: state.update.doc_upload_menu_on,
    affidavit_menu_on: state.update.affidavit_menu_on,
    message_modal_on: state.update.message_modal_on,
    job_selection_on: state.update.job_selection_on
  }
}

export default connect(
  mapStateToProps,
  {toggleBatchActions, togglePDFOn, enterModal, startReport, updateRowSelection}
)(JobModals)

const contStyles = {
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: '50%',
    height: '50%',
    backgroundColor: '#fff',
    border: '2px solid #000',
  },
  paperMini: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: 400,
    height: 350,
    backgroundColor: '#fff',
    border: '2px solid #000',
  },
  paperXL: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'column',
    width: '90%',
    height: '90%',
    backgroundColor: '#fff',
    overflowY: 'scroll',
  },
  form: {
    margin: 30,
    minWidth: 120,
  },
  button: {
    marginTop: 60
  },
  dnd: {
    width: 550,
    height: 100,
    margin: 10
  }
}
